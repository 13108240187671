import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { useMeta, appColors } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"

import { Layout, Seo } from "components/common"

import { ShipFullFeatures, AppPricing } from "src/sections/app"

const ShipFullFeaturesPage = () => {
  const { app } = useStaticQuery(QUERY)
  const slug = "ship"
  const appFullFeatures = app.appFullFeatures

  const meta = useMeta(appFullFeatures)

  return (
    <Layout>
      <Seo {...meta} />

      <MobileNav slug={slug} />
      <StyledFullFeaturesPage slug={slug}>
        <PrimaryNav slug={slug} />
        <AppStickyNav
          disabled={app.disabled}
          slug={slug}
          salesforceAppexchangeUrl={app.salesforceAppexchangeUrl}
        />

        <ShipFullFeatures {...appFullFeatures} />
      </StyledFullFeaturesPage>
      <PricingWrap slug={slug}>
        <AppPricing
          disabled={app.disabled}
          {...app.pricing}
          salesforceAppexchangeUrl={app.salesforceAppexchangeUrl}
        />
      </PricingWrap>
    </Layout>
  )
}

const StyledFullFeaturesPage = styled.div``

const PricingWrap = styled.section`
  --app-theme-color: ${({ slug }) => appColors[slug].dark};
  --app-gradient-color: ${({ slug }) => appColors[slug].textGradient};
`

const QUERY = graphql`
  {
    app: contentfulApp(slug: { eq: "ship" }) {
      ...App
    }
  }
`

export default ShipFullFeaturesPage
